@import "variables";
// Footer
.siteFooter {
  padding: 0 !important;
  background: #2C2925 url("https://www.datocms-assets.com/74813/1704827390-footer-image.png") right top / contain no-repeat;
  max-width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1060px) {
    background: #2C2925;
  }
}

// Footer Branding
.siteFooter > .siteInfo {
  padding-top: var(--global--spacing-vertical);
  color: var(--footer--color-text);
  font-family: var(--footer--font-family);
  font-size: var(--footer--font-size);
  line-height: var(--global--line-height-body);
  border-top: 3px solid var(--global--color-border);

  .siteName {
    text-transform: var(--branding--title--text-transform);
    font-size: var(--branding--title--font-size);
  }

  @media only screen and (min-width: 822px) {
    display: flex;
    align-items: center;
  }

  a {
    color: var(--footer--color-link);

    &:link,
    &:visited,
    &:active {
      color: var(--footer--color-link);
    }

    &:hover {
      color: var(--footer--color-link-hover);
    }

    &:focus {
      color: var(--footer--color-link-hover);

      .isDarkTheme & {
        color: var(--wp--style--color--link, var(--global--color-background));
      }

      // Change colors when the body background is white.
      .hasBackgroundWhite & {
        color: var(--wp--style--color--link, var(--global--color-white));
      }
    }
  }
}

.siteFooterWrap {
  position: relative;
  max-width: 1700px;
  padding-top: 55px;
  padding-bottom: 75px;
  margin: 0;

  @include tablet {
    background: transparent;
  }
  @include mobile {
    background: transparent;
  }
}

.footerContainer {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding: 0 40px;

  @media screen and (max-width: 1199px) {
    padding: 0 25px;
  }

  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left;
  }

  li {
    margin: 0;
    display: table-column;
    padding: 10px;
  }
}

.footerInnerWrap {
  h2,
  .widget h2 {
    font-family: "AktivGrotesk Ex Black Italic";
    text-transform: uppercase;
    font-size: 26px;
    margin-bottom: 20px;
    line-height: 26px;
    color: var(--global--color-gold);

    @media screen and (max-width: 767px) {
      font-size: 20.7952px;
      line-height: 23px;
      letter-spacing: -1.24771px;
    }
  }
}

.footerMain {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.footerCol {
  padding: 0 15px;

  @media screen and (max-width: 1059px) {
    &:first-child,
    &:nth-child(2) {
      width: 100%;
    }

    &:last-child {
      display: block;
      width: 100% !important;
    }
  }

  @media screen and (min-width: 1061px) {
    &:first-child {
      width: 37%;
    }

    &:last-child {
      width: 23%;
    }

    &:nth-child(2) {
      width: 40%;
    }
  }

  @media screen and (max-width: 1100px) {
    display: flex;

    &:first-child {
      order: 2;
      margin-bottom: 70px;
    }

    &:last-child {
      order: 1;
      margin-bottom: 85px;
    }

    &:nth-child(2) {
      order: 3;
    }
  }
}

.footerInnerWrap {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;

  .footerNavWrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;

    & > div {
      flex-grow: 1;
      width: 20%;

      @media screen and (max-width: 350px) {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      &:last-child {
        @media screen and (min-width: 1300px) {
          padding-left: 50px;
        }
      }
    }
  }

  nav {
    li {
      display: block;
      padding: 0;
      margin-bottom: 5px;

      a {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: #ffffff;
      }
    }
  }

  .widget_media_image {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

.footerCopyright {
  &.small {
    p {
      justify-content: center;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      margin-bottom: 10px;
      margin-top: 0;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &.desktop {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.footerCopyrightWrap {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .footerSocial {
    @media screen and (max-width: 767px) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    .socialLink {
      margin: 0 17px 0 0;

      @media screen and (max-width: 767px) {
        margin: 0 6px 0 0;
      }

      a {
        padding: 0;
        display: block;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
      }

      svg {
        display: none;
        width: 1em;
        height: 1em;
      }

      &.instagram {
        a {
          width: 28px;
          height: 28px;
          background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-instagram.png");

          &:hover {
            transform: initial;
            background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-instagram-hover.png");
          }
        }
      }

      &.twitter {
        a {
          svg {
            display: block;
          }
        }
      }

      &.facebook {
        a {
          width: 15px;
          height: 29px;
          background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-facebook.png");

          &:hover {
            transform: initial;
            background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-facebook-hover.png");
          }
        }
      }

      &.youtube {
        a {
          width: 39px;
          height: 27px;
          background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-youtube.png");

          &:hover {
            transform: initial;
            background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-youtube-hover.png");
          }
        }
      }

      &.tiktok {
        a {
          width: 25px;
          height: 29px;
          background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-tiktok.png");

          &:hover {
            transform: initial;
            background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-tiktok-hover.png");
          }
        }
      }
    }
  }

  .footerCopyright {
    margin-top: 30px;

    p {
      font-size: 10.5515px;
      font-weight: 400;
      line-height: 12px;
      margin-bottom: 10px;
      margin-top: 0;
      color: #fff;
      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: #ffffff;
        text-decoration: underline;
      }
    }
  }

  .widget_block:last-of-type {
    @media screen and (max-width: 767px) {
      max-width: 340px;
    }
  }
}

@media screen and (min-width: 1060px) {
  .siteFooterWrap {
    position: relative;
  }

  .footerCol:last-child {
    position: absolute;
    right: 30px;
    width: 320px;
    top: 50%;
    transform: translateY(-50%);
  }

  .footerCol:nth-child(2) {
    width: 35%;
  }

  .footerCol:first-child {
    width: 75%;
  }

  .footerInnerWrap .footerNavWrap > div:last-child {
    padding-left: 15px;
  }
}

@media screen and (max-width: 1300px) {
  .siteFooterWrap {
    background-position-x: 110%;
  }

  .footerCol:last-child {
    right: 0;
  }
}

@media screen and (max-width: 1199px) {
  .siteFooterWrap {
    background-position-x: 119%;
  }

  .footerCol:last-child {
    width: 280px;
  }

  .footerInnerWrap h2,
  .footerInnerWrap .widget h2 {
    font-size: 22px;
  }
}

.newsletterContainer {
  width: 40% !important;

  @media screen and (max-width: 1060px) {
    margin-top: 20px;
    width: 100% !important;
  }
}
