.siteHeader {
  width: 100% !important;
  background-color: white;
  .headerContent {
    display: flex;
    align-items: flex-start;
    flex-wrap: no-wrap;
    row-gap: var(
      --global--spacing-vertical
    ); // Add space in case the menu wraps below the site branding.
    position: relative;
    align-items: center;
    justify-content: space-between;
    max-width: 1700px;
    margin: auto;
    padding: 10px 40px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      height: 1px;
      width: calc(100% - 80px);
      background-color: transparent;
      @media screen and (max-width: 1200px) {
        width: calc(100% - 50px);
      }
    }

    @media only screen and (max-width: 1440px) {
      padding: 10px 25px;
      max-width: 100%;
      &.notHome {
        &::before,
        &::after {
          width: calc(100% - 50px);
        }
      }
    }

    @media only screen and (max-width: 767px) {
      padding: 0 20px;
      &::before,
      &::after {
        width: calc(100% - 40px);
      }
    }

    @media only screen and (max-width: 499px) {
      justify-content: space-between;

      &::after,
      &::before {
        display: none;
      }
    }
  }
}

.stickyHeader {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--global--color-black);
  z-index: 999;

  &::after,
  &::before {
    display: none !important;
  }
}

.menuButton {
  display: flex;
  margin-left: auto;
  padding: calc(
      var(--button--padding-vertical) - (0.25 * var(--global--spacing-unit))
    )
    0;
  font-size: var(--primary-nav--font-size-button);
  font-weight: var(--primary-nav--font-weight-button);
  background-color: transparent !important;
  border: none;
  color: var(--primary-nav--color-link);

  &:focus {
    outline: none;
  }
  transition: 0.3s all;

  .menuIconHover {
    display: none;
  }

  &:hover {
    transition: 0.3s all;

    .menuIcon {
      display: none;
    }

    .menuIconHover {
      display: block;
    }
  }
}

.dropdownIcon {
  display: flex;
  align-items: center;

  .svg-icon {
    height: 25px;
    width: 57px;
    margin-left: calc(0.25 * var(--global--spacing-unit));
  }

  &.dark svg path {
    fill: var(--global--color-black);
  }
}

.headerRight {
  display: flex;
  align-items: center;

  svg {
    height: 27px;
    width: 55px;
  }
}

.btnLink {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 111px;
  border: 1px solid var(--global--color-white);
  border-radius: 12px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-left: 8px;
  font-weight: 700;

  @media screen and (max-width: 767px) {
    width: 71px;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      padding-top: 0;
    }
  }

  &:hover {
    background-color: var(--global--color-white);
    color: var(--global--color-black);
  }

  &.dark {
    color: var(--global--color-black);
    border-color: var(--global--color-black);

    &:hover {
      background-color: var(--global--color-black);
      color: var(--global--color-white);
      border-color: var(--global--color-black);
    }
  }
}

.headerRightCart, .headerRightProfile {
  margin-left: 8px;
  transition: 0.3s all;
  a {
    display: inline-flex;
    padding: 10px 0;
    transition: 0.3s all;
    &:hover {
      transition: 0.3s all;
      svg path {
        fill: var(--global--color-white);
      }
    }
  }
  &.dark {
    svg path {
      stroke: var(--global--color-black);
    }
    a {
      transition: 0.3s all;
      &:hover {
        transition: 0.3s all;
        svg path {
          fill: var(--global--color-black);
        }
      }
    }
  }
}
.shopAllButton {
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.primaryNavigation {
  margin-left: 8px;
  transition: 0.3s all;
  position: static;
  top: var(--global--admin-bar--height);
  right: 0;
  color: var(--primary-nav--color-text);
  font-size: var(--primary-nav--font-size);
  line-height: 1.15;
  margin-top: 0;
  margin-bottom: 0;

  @media only screen and (min-width: 482px) {
    position: static;
  }

  // Menu Item Link
  a {
    display: block;
    font-family: var(--primary-nav--font-family-mobile);
    font-size: var(--primary-nav--font-size-mobile);
    font-weight: var(--primary-nav--font-weight);
    padding: var(--primary-nav--padding) 0;
    text-decoration: none;

    @media only screen and (min-width: 482px) {
      display: block;
      font-family: var(--primary-nav--font-family);
      font-size: var(--primary-nav--font-size);
      font-weight: var(--primary-nav--font-weight);
    }

    + svg {
      fill: var(--primary-nav--color-text);
    }

    &:hover,
    &:link,
    &:visited {
      color: var(--primary-nav--color-link-hover);
    }

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      position: relative;
      z-index: 99999; // Ensure focus styles appear above absolute positioned elements
      outline-offset: 0;
      text-decoration-thickness: 2px;
    }
  }
}

.headerLeft {
  display: flex;
  align-items: center;
}

.headerLeftCart {
  display: none;

  a {
    transition: 0.3s all;
    &:hover {
      transition: 0.3s all;
      svg path {
        fill: var(--global--color-white);
      }
    }
    padding: 10px 0;
    display: inline-flex;
    svg {
      height: 25px;
    }
  }

  &.dark {
    svg path {
      stroke: var(--global--color-black);
    }
    a {
      transition: 0.3s all;
      &:hover {
        transition: 0.3s all;
        svg path {
          fill: var(--global--color-black);
        }
      }
    }
  }
}

.siteBranding {
  color: var(--branding--color-text);
  margin-right: 140px;

  &:last-child {
    margin-right: 0;
    width: 100%;
    text-align: center;
  }

  @media only screen and (min-width: 482px) {
    margin-right: initial;
    margin-top: 0; // Align the baseline of the site title with the primary menu
  }
  @media only screen and (max-width: 499px) {
    margin: 0;
  }
}

.siteLogo {
  display: inline-flex;
  margin: calc(var(--global--spacing-vertical) / 2) 0;

  a {
    display: inline-flex;
  }

  &.home {
    display: none;
  }

  @media only screen and (max-width: 481px) {
    position: absolute;
    padding-top: calc(0.5 * var(--global--spacing-vertical));
    margin-top: 0;
    top: var(--global--admin-bar--height);
    svg {
      width: 145px;
    }
    img {
      max-height: calc(
        var(--button--padding-vertical) - (0.25 * var(--global--spacing-unit)) +
          1.7em
      );
    }
  }
  svg path {
    &:not([fill="var(--global--color-logo)"]) {
      fill: var(--global--color-black);
    }
  }


  @media only screen and (max-width: 499px) {
    padding-top: 15px;
    margin-top: 0;
    position: relative;
    top: unset;
    svg {
      width: 145px;
    }
  }
}

.homeSiteLogo {
  display: none;

  &.home {
    height: auto;
    display: flex;
    align-items: center;
    svg {
      width: 215px;
      height: 58px;
    }
    @media only screen and (max-width: 499px) {
      svg {
        width: 145px;
      }
    }
  }

  &.dark svg path {
    &:not([fill="var(--global--color-logo)"]) {
      fill: var(--global--color-black);
    }
  }
}

.timeMusicWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wpSocialLink {
  margin: 0 17px 0 0;
  background: none;
  padding: 10px;

  @media screen and (max-width: 374px) {
    margin: 0 6px 0 0;
  }

  a {
    padding: 0;
    display: block;
    text-decoration: none;
    border-bottom: 0;
    box-shadow: none;
    &:hover {
      text-decoration: none;
      border-bottom: 0;
      box-shadow: none;
    }
  }

  svg {
    display: none;
  }
}

.wpBlockSocialLink {
  display: block;
  border-radius: 9999px;
  transition: transform 0.1s ease;
  height: auto;
  transition-delay: 0s !important;
  transition-duration: 0s !important;
  a {
    display: block;
    line-height: 0;
    transition: transform 0.1s ease;
  }
  &:hover {
    transform: scale(1.1);
  }
}

.wpSocialLinkTiktok {
  color: #000;
  a {
    width: 25px;
    height: 29px;
    background-position: center;
    background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-tiktok.png");
    background-size: contain;
    background-repeat: no-repeat;

    &:hover {
      transform: initial;
      background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-tiktok-hover.png");
    }
    svg {
      display: none;
    }
  }
}

.wpSocialLinkYoutube {
  color: red;
  a {
    width: 39px;
    height: 27px;
    background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-youtube.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &:hover {
      transform: initial;
      background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-youtube-hover.png");
    }
    svg {
      display: none;
    }
  }
}

.wpSocialLinkFacebook {
  color: #1778f2;

  a {
    width: 15px;
    height: 29px;
    background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-facebook.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &:hover {
      transform: initial;
      background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-facebook-hover.png");
    }
    svg {
      display: none;
    }
  }
}

.wpSocialLinkTwitter {
  color: #1da1f2;
  svg {
    display: block;
  }
}

.wpSocialLinkInstagram {
  color: #f00075;
  a {
    width: 28px;
    height: 28px;
    background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-instagram.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &:hover {
      transform: initial;
      background-image: url("https://assets.decovostatic.com/rockstar-energy/assets/images/icon-instagram-hover.png");
    }
    svg {
      display: none;
    }
  }
}

.wpBlockSocialLinks {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  text-indent: 0;
  margin-left: 0;
  font-size: 24px;
  a:focus {
    color: var(--global--color-primary);
  }
}

.menuBottomInnerWrap {
  display: flex;
  margin-top: 70px;
  height: auto;
  margin-bottom: 30px;
  max-width: 1700px;
  margin: auto;
  padding: calc(var(--global--spacing-vertical) - 20px)
    calc(var(--global--spacing-vertical) + 10px);
  @media screen and (max-width: 1199px) {
    padding: 50px 25px 30px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  padding-top: 80px;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    margin-top: 30px;
  }

  h2 {
    font-family: "AktivGrotesk Ex Black Italic";
    text-transform: uppercase;
    color: var(--global--color-gold);
    font-size: 26px;
    letter-spacing: 1.2px;
    margin-bottom: 20px;
    line-height: 26px;
  }

  nav {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: block;
        padding: 0;
        margin-bottom: 15px;
      }

      a {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1.6px;
        color: var(--global--color-white) !important;
        padding: 0;
        text-transform: uppercase;
      }
    }
  }
}

.menuBottomWrap {
  background-color: #2C2925;
  flex-grow: 1;
  padding-bottom: 60px;
}

.widget {
  > * {
    margin-top: var(--global--spacing-unit);
    margin-bottom: var(--global--spacing-unit);

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: var(--widget--font-weight-title);
    line-height: var(--widget--line-height-title);
  }

  h1 {
    font-size: var(--global--font-size-md);
  }

  h2 {
    font-size: var(--global--font-size-sm);
  }

  h3 {
    font-size: var(--global--font-size-xs);
  }

  h4 {
    font-size: var(--global--font-size-xs);
  }

  h5 {
    font-size: var(--global--font-size-xs);
  }

  h6 {
    font-size: var(--global--font-size-xs);
  }

  h4 {
    font-family: "AktivGrotesk Ex Black Italic";
    font-size: 15.4467px;
    line-height: 15px;
    letter-spacing: 0.617868px;
    text-transform: uppercase;
    margin-bottom: 10px !important;
    color: var(--global--color-focus-tertiary);
  }
}

.headerSocials {
  > div {
    margin-bottom: 20px;
  }
}

.menuTopWrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 1700px;
  margin: auto;
  padding: calc(var(--global--spacing-vertical) - 20px)
    calc(var(--global--spacing-vertical) + 10px);
  @media screen and (max-width: 1199px) {
    padding: 10px 25px 30px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.primaryMenuItem {
  transition: all 0.5s ease;

  a {
    color: var(--global--color-white);
    font-family: "AktivGrotesk Ex Black Italic";
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 1px;
    padding: 20px 0;
    text-transform: uppercase;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: var(--global--color-focus-tertiary);
    background-size: 100% 0;
    background-position: bottom;
    background-repeat: no-repeat;
    transition: all 0.7s cubic-bezier(0.16, 1, 0.3, 1);

    &:hover {
      background-size: 100% 100%;
      text-decoration: none;
      -webkit-text-stroke: 0px;
    }

    @media screen and (max-width: 767px) {
      font-size: 25px;
      line-height: 25px;
      letter-spacing: -0.887629px;
      padding: 8px 0;
    }

    @media screen and (max-width: 480px) {
      font-size: 20px;
      line-height: 24px;
      padding: 8px 0;
    }

    @media screen and (max-width: 375px) {
      padding: 8px 0;
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.menuWrapper {
  list-style: none;

  @media screen and (max-width: 991px) {
    column-count: 2;
  }
}

.primaryMenuContainer {
  transition: all 0.15s ease-in-out;
  @media only screen and (min-width: 482px) {
    width: 33%;
    position: fixed;
    visibility: hidden;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // Height of the menu-button-container using font size, line height, and total padding, plus 5px so the focus of the first item is visible.
    padding-top: calc(
      var(--button--line-height) * var(--primary-nav--font-size-button) + 42px +
        5px
    );
    padding-left: var(--global--spacing-unit);
    padding-right: var(--global--spacing-unit);
    padding-bottom: var(--global--spacing-horizontal);
    background-color: var(--global--color-background);
    transform: translateY(var(--global--spacing-vertical));
    visibility: visible;
    opacity: 1;
    position: relative;
    padding: 0;
    background-color: transparent;
    overflow: initial;
    transform: none;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 20px;
  }
}

.megaMenuTop {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 991px) {
    position: absolute;
    // top: 20px;
    width: calc(100% - var(--global--spacing-horizontal) * 2);
  }
}

.wpBlockColumn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 0 !important;

  @media screen and (max-width: 781px) {
    flex-basis: calc(33.33% - 10px) !important;
  }

  &:not(:first-child) {
    margin-left: 30px;

    @media screen and (max-width: 781px) {
      margin-left: 10px;
    }
  }

  figure {
    margin: 0;
  }

  h2 {
    font-size: 34px;
    line-height: 78px;
    letter-spacing: -1.2px;
    font-family: "AktivGrotesk Ex Black Italic";
    color: transparent;
    text-transform: uppercase;
    transition: all 0.5s ease;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: transparent !important;
      font-size: 34px;
      line-height: 78px;
      letter-spacing: -1.2px;
      padding: 0;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-stroke: 1px var(--global--color-white);
      background-image: linear-gradient(#fff, #fff);
      background-size: 100% 0;
      background-position: bottom;
      background-repeat: no-repeat;
      transition: all 0.7s cubic-bezier(0.16, 1, 0.3, 1);
      height: 100%;
      width: 100%;

      @media screen and (max-width: 767px) {
        font-size: 17.3901px;
        line-height: 40px;
        letter-spacing: -0.613769px;
      }

      &:hover {
        background-size: 100% 100%;
        text-decoration: none;
        text-decoration: none;
        -webkit-text-stroke: 0;
      }
    }

    @media screen and (max-width: 767px) {
      font-size: 17.3901px;
      line-height: 40px;
      letter-spacing: -0.613769px;
    }
  }

  @media screen and (max-width: 375px) {
    flex-basis: 100% !important;
    margin-bottom: 10px !important;

    &:not(:first-child) {
      margin-left: 0;
    }
  }
}

.wpBlockColumns {
  clear: both;
  display: flex;
  margin-bottom: 1.75em;
  box-sizing: border-box;
  flex-wrap: wrap;
  @media (min-width: 782px) {
    flex-wrap: nowrap;
  }
}

.wpBlockImage {
  margin: 0 0 1em;
  margin-top: 0;
  margin-bottom: 0;

  img {
    max-width: 100%;
    border-radius: inherit;
  }
  figure {
    margin: 0;
  }
  > div {
    text-align: center;
  }

  text-align: center;
  a:focus img {
    outline-offset: 2px;
  }

  // Remove top margins from the following element when previous image block is floated
  + * {
    margin-top: 0;
  }
}

.megaMenuCategoryWrap {
  width: 67%;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.headerBottomMenu {
  display: flex;
  @media screen and (max-width: 991px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 460px) {
    flex-direction: column;
  }

  > div {
    min-width: 300px;

    @media screen and (max-width: 991px) {
      width: 50%;
      min-width: auto;
    }

    @media screen and (max-width: 460px) {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
  h2 {
    font-size: 30px;
    line-height: 1;

    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }
}

.primaryMenuWraper {
  z-index: 9999 !important;
  position: fixed;
  top: 169px;
  left: 0;
  bottom: auto;
  background-color: var(--global--color-focus);
  z-index: 99;
  margin: 0;
  width: 100%;
  height: calc(100vh - 140px);
  overflow-y: auto;
  display: none;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    overflow-y: auto;
  }

  @media screen and (max-width: 767px) {
    top: 150px;
    height: calc(100vh - 150px);
  }

  @media screen and (max-width: 499px) {
    top: 124px;
    height: calc(100vh - 124px);
  }

  &.isSticky {
    top: 78px;
    height: calc(100vh - 78px);

    @media screen and (max-width: 767px) {
      top: 65px;
      height: calc(100vh - 65px);
    }
  }
}
